const toLink = (linkId, offset) => {
    if(!offset) offset = 0;
    const target = document.getElementById(linkId);
    if(target) {
        const ypos = target.getBoundingClientRect().top + window.pageYOffset;
        const REM = window.innerWidth >= window.innerHeight ? 0.01 * window.innerHeight + 10 : 0.01 * window.innerWidth + 10;   //Calcula el valor de 1 rem actual 
        window.scrollTo({ top: ypos - (4 * REM) + 1 + (offset * REM) });                                                            //4 rem es la altura del navbar 
    }
}

export default toLink;