import React from 'react';
import "./serviciosSeccion.css";
import HeaderSecciones from '../headerSecciones/HeaderSecciones';
import CarouselZoomSecciones from '../carouselZoomSecciones/CarouselZoomSecciones';

const ServiciosSeccion = () => {

    const imagesCarouselList = <>
        <img src="/images/servicio/1.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/servicio/2.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/servicio/3.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg opb'/> 
        <img src="/images/servicio/4.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/servicio/5.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
    </>
    
    return (
        <>
            <div className='seccionesContHeader OoS'>
                <div className='seccionesContTituloHeader navBarDimensiones flex'>
                    <p className="seccionesHeaderTitulo">Servicios</p>
                </div>
                <HeaderSecciones src="/images/headers/p3.jpg" clase="serviciosImgHeader"/>
            </div>

            <div className='seccionesCont igualarAlturas flex wrap'>
                <div className='seccionesContTexto seccionServicio flex column OoS'>
                    <p className='seccionEmpresaTituloTexto'>En Garcia Polietilenos</p>
                    <p>Nos enorgullece brindarle a nuestros clientes un buen servicio, centrándonos en su comodidad. Además estamos para poder ofrecerle un buen asesoramiento, estamos comprometidos en proporcionar presupuestos personalizados sin ningún costo adicional.</p> <br />
                    <p>Nos especializamos en la fabricación y confección de productos de polietileno de baja densidad en diversas medidas para así poder adaptándonos a sus necesidades específicas. Ya sea que necesite bolsas, bobinas, o otro tipo de producto de polietileno de baja densidad.</p> <br />
                    <p>Para garantizar una buena atención nos aseguramos de contar con un buen servicio. Independientemente de su ubicación, nos encargamos de poder llevar sus pedidos, brindándole la comodidad necesaria para  así poder recibir los productos sin complicaciones.</p> <br />
                    <p>En García Polietilenos, nos esforzamos por superar las expectativas de nuestros clientes y nos dedicamos a proporcionar productos de calidad junto con un buen servicio al cliente.</p> <br />
                </div>
                <div className='seccionesContCarousel seccionContCarouselServicio'>
                    <CarouselZoomSecciones imagesList={imagesCarouselList}/>
                </div>
            </div>
        </>
    );
}

export default ServiciosSeccion;
