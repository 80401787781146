import Swal from "sweetalert2";

const swalPopUp = (title, text, icon) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
    })
}

export default swalPopUp;