import React from 'react';
import "./headerSecciones.css";

const HeaderSecciones = ({src, clase}) => {
    return (
        <div className='contHeaderSecciones flex' id="inicio">
            <img src={src} alt="productos Garcia Polietilenos" className={`imgHeaderSecciones ${clase}`}/> 
        </div>
    );
}

export default HeaderSecciones;
