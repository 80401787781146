import React from 'react';
import { HashLink } from "react-router-hash-link";

const scrollWithoffset = (e) => {                                            //Ajuste de offset con el link de contacto
    const altoNavBar = document.querySelector(".navBarCont").offsetHeight;
    const ypos = e.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({top: ypos - (1.5 * altoNavBar), behavior: "smooth"});
}

const MenuOpciones = ({clase}) => {
    return (
        <div className={`menuContListaOpciones flex ${clase}`}>

            <HashLink to="/#inicio" smooth={true} className="navBarContLogoPortrait">
                <img src="/images/logo_navbar.png" className="navBarLogoPortrait" alt="Garcia Polietilenos Logo" />
            </HashLink>
            <HashLink className="menuContOpcionYsubOpciones flex" to="/#inicio" smooth={true}><p className='menuOpcion'>Inicio</p></HashLink>
            <HashLink className="menuContOpcionYsubOpciones flex" to="/productos#top" smooth={true}><p className='menuOpcion'>Productos</p></HashLink>
            {/* <div className="menuContOpcionYsubOpciones flex column">
                        <div className="menuContOpcion flex">
                            <p className='menuOpcion'>Productos</p>
                        </div>
                        <div className="menuContSubOpciones flex column" name="subMenu">
                            <p className="menuSubOpcion flex">Bolsas camisetas</p>
                            <p className="menuSubOpcion flex">Bolsas de residuo</p>
                            <p className="menuSubOpcion flex">Bolsas de consorcio</p>
                            <p className="menuSubOpcion flex">Bolsas para carbón</p>
                            <p className="menuSubOpcion flex">Bolsas para leña</p>
                            <p className="menuSubOpcion flex">Bolsas para tierra</p>
                            <p className="menuSubOpcion flex">Bolsas para escombros</p>
                            <p className="menuSubOpcion flex">Bolsas patológicas</p>
                            <p className="menuSubOpcion flex">Bolsas para leña</p>
                            <p className="menuSubOpcion flex">Bobinas de polietileno</p>
                        </div> 
                    </div> */}
            <HashLink className="menuContOpcionYsubOpciones flex" to="/servicios#top" smooth={true}><p className='menuOpcion'>Servicios</p></HashLink>
            <HashLink className="menuContOpcionYsubOpciones flex" to="/empresa#top" smooth={true}><p className='menuOpcion'>Empresa</p></HashLink>
            <HashLink className="menuContOpcionYsubOpciones flex" to="/#contacto" scroll={(e) => scrollWithoffset(e)}><p className='menuOpcion'>Contacto</p></HashLink>

        </div>
    );
}

export default MenuOpciones;
