import React from 'react';
import Header from '../header/Header';
import Contacto from '../contacto/Contacto';
import Videos from '../videos/Videos';
import "./home.css";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toLink from '../../utils/toLink';

const Home = () => {

    const {id} = useParams();
    
    useEffect(() => {
        
        if(id) toLink(id);
        
    }, [id]);

    return (
        <>
            <Header/>
            <div className='homeContSecciones wrap'>
                <Videos/>
                <Contacto/>
            </div>
        </>
    );
}

export default Home;
