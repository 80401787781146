import React from 'react';
import "./productosSeccion.css";
import CarouselZoomSecciones from '../carouselZoomSecciones/CarouselZoomSecciones';
import HeaderSecciones from '../headerSecciones/HeaderSecciones';

const ProductosSeccion = () => {

    const imagesCarouselList = <>
        <img src="/images/productos/1.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg opl'/> 
        <img src="/images/productos/2.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/productos/3.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/productos/4.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg opb'/> 
    </>
    
    return (
        <>
            <div className='seccionesContHeader OoS'>
                <div className='seccionesContTituloHeader navBarDimensiones flex'>
                    <p className="seccionesHeaderTitulo">Nuestros Productos</p>
                </div>
                <HeaderSecciones src="/images/headers/p2.jpg" clase="opc"/>
            </div>

            <div className='seccionesCont igualarAlturas flex wrap'>
                <div className='seccionesContTexto flex column OoS'>
                    <div className='productosTitulos flex productos1merItemListaCont'><div className='productosContTilde flex'></div><p className='productos1merItemListaTexto'>Confeccionamos bolsas en todas las medidas</p></div>
                    {/* <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas camisetas de baja densidad</p></div> */}
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas de residuo</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas de consorcio</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas para para envasar carbón</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas para envasar leña</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas para envasar tierra, para viveros</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas para envasar escombros, para corralones</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Bolsas patológicas</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Trabajamos con bolsas de politileno de baja densidad</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Impresión hasta 4 colores personalizados</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>Fabricación de bobinas de polietileno B/D</p></div>
                    <div className='productosTitulos flex'><div className='productosContTilde flex'><img className='tildeProductos'  src="/images/check.png" alt="tildeProductos" /></div><p>(Láminas, Tubo, Con fuelle, Impreso o Liso)</p></div>
                </div>
                <div className='seccionesContCarousel'>
                    <CarouselZoomSecciones imagesList={imagesCarouselList}/>
                </div>
            </div>
        </>
    );
}

export default ProductosSeccion;
