import React from 'react';
import "./videos.css";
import { useEffect } from 'react';

const Videos = () => {
    
    useEffect(() => {
        const videos = document.getElementsByClassName("videosHome");
        window.addEventListener("scroll", () => {           //Los videos inician cuando son visibles
            for (const video of videos) {
                if (video.getBoundingClientRect().top < window.innerHeight) video.play();
            }
        })
    }, []);

    return (
        <div className='videosCont flex column'>
            <video src="/videos/1.mp4" muted={true} loop={true} controls={false} allowFullScreen={false} playsInline={true} type="video/mp4" className='videosHome OoS'/>
            <video src="/videos/2.mp4" muted={true} loop={true} controls={false} allowFullScreen={false} playsInline={true} type="video/mp4" className='videosHome OoS'/>
        </div>
    );
}

export default Videos;
