import PuffLoader from "react-spinners/PuffLoader";
import React from 'react';
import "./spinners.css";

const Spinner1 = () => {
    return (
        <div className="contSpinners flex">
            <PuffLoader color="orange" size={100}/>
        </div>
    );
}

export default Spinner1;