import React from 'react';
import "./logosFlotantes.css";
import gtag_report_conversion from '../../ads/clickWhatsApp';

const WathsappLogo = () => {
    return (
        <a href="https://wa.me/1137563767" target='_blank' rel="noreferrer" className='logoFlotanteLink flex' onClick={() => gtag_report_conversion()}><img className='logoFlotanteImg' src="/images/whatsapp.png" alt="whatsApp Logo" /></a>
    );
}

// const InstagramLogo = () => {
//     return (
//         <a href="https://instagram.com/garciapolietilenos_?igshid=MzRlODBiNWFlZA==" target='_blank' rel="noreferrer" className='logoFlotanteLink flex'><img className='logoFlotanteImg logoFlotanteImgInstagram' src="/images/icons/instagram.png" alt="Instagram Logo" /></a>
//     );
// }

// const FacebookLogo = () => {
//     return (
//         <a href="https://www.facebook.com/GarciaPolietilenos" target='_blank' rel="noreferrer" className='logoFlotanteLink flex'><img className='logoFlotanteImg logoFlotanteImgInstagram' src="/images/icons/facebook.png" alt="Facebook Logo" /></a>
//     );
// }

const ContLogosFlotantes = () => {
    return (
        <div className="logosFlotantesCont flex column">
            {/* <FacebookLogo/> */}
            {/* <InstagramLogo/> */}
            <WathsappLogo/>
        </div>
    );
}

export default ContLogosFlotantes;
