// <!-- Event snippet for Click en Boton de WhatsApp conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->

function gtag_report_conversion(url) {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-11274498058/NMBYCLrjosoYEIrgjIAq',
        'event_callback': callback
    });
    return false;
}

export default gtag_report_conversion;


