import React from 'react';
import "./header.css";
import CarouselZoom from '../carouselZoom/CarouselZoom';

const Header = () => {
    return (
        <div className='seccionHeader' id="inicio">
            <div className='headerTitulos flex column'>                    
                <h1 className='headerTitulo OoS'>Polietilenos Garcia</h1>
                <h2 className='headerSubTitulo OoS'>Fábrica de bolsas de polietileno de baja densidad</h2>
            </div>
            <CarouselZoom/>
            <img src="images/logo.png" alt="Logo" className="headerLogo OoS" />
        </div>
    );
}

export default Header;
