import React from 'react';
import "./empresaSeccion.css";
import HeaderSecciones from '../headerSecciones/HeaderSecciones';
import CarouselZoomSecciones from '../carouselZoomSecciones/CarouselZoomSecciones';

const EmpresaSeccion = () => {

    const imagesCarouselList = <>
        <img src="/images/empresa/3min.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg opb'/> 
        <img src="/images/empresa/1min.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/empresa/2min.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/empresa/4min.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
        <img src="/images/empresa/5min.jpg" alt="productos Garcia Polietilenos" className='carouselZoomSeccionesImg'/> 
    </>
   
    return (
        <>
            <div className='seccionesContHeader OoS'>
                <div className='seccionesContTituloHeader navBarDimensiones flex'>
                    <p className="seccionesHeaderTitulo">Nosotros</p>
                </div>
                <HeaderSecciones src="/images/headers/p4.jpg" clase="empresaImgHeader"/>
            </div>

            <div className='seccionesCont empresaSeccionCont igualarAlturas flex wrap'>
                <div className='seccionesContTexto seccionEmpresa flex column OoS'>
                    <p className='seccionEmpresaTituloTexto'>En García Polietilenos</p>
                    <p>Nos dedicamos a la fabricación de bolsas de polietileno de baja densidad con una amplia trayectoria en el rubro de mas de 30 años. </p> <br />
                    <p>Conformamos una empresa con un excelente grupo humano, dedicado a ofrecer a cada uno de nuestros clientes la mejor atención y calidad en nuestros productos.</p> <br />
                    <p>Fabricamos material 100% reutilizable y reciclable. Ofrecemos asesoramiento en medidas, micronajes y una amplia gama de colores tanto para la bolsa, como para su impresión.</p>
                </div>
                <div className='seccionesContCarousel'>
                    <CarouselZoomSecciones imagesList={imagesCarouselList}/>
                </div>
            </div>

            <div className='seccionesCont empresaVideosCont flex wrap'>
                <video src="/videos/3.mp4" muted={true} loop={true} autoPlay={true} controls={false} allowFullScreen={false} playsInline={true} type="video/mp4" className='empresaVideos OoS'/>
                <video src="/videos/4.mp4" muted={true} loop={true} autoPlay={true} controls={false} allowFullScreen={false} playsInline={true} type="video/mp4" className='empresaVideos OoS'/>
            </div>
        </>
    );
}

export default EmpresaSeccion;
