import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar2 from './components/navBar2/NavBar2';
import Home from './components/home/Home';
import "../src/components/generalStyles.css";
import "./components/generalStyles.css";
import ContLogosFlotantes from './components/logosFlotantes/LogosFlotantes';
import ProductosSeccion from './components/productosSeccion/ProductosSeccion';
import Footer from './components/footer/Footer';
import EmpresaSeccion from './components/empresaSeccion/EmpresaSeccion';
import ServiciosSeccion from './components/serviciosSeccion/ServiciosSeccion';

function App() {
    return (
        <BrowserRouter>
            <NavBar2/>
            <ContLogosFlotantes/>
                <Routes>
                    <Route path="/" element={<Home/>} /> 
                    <Route path="/:id" element={<Home/>} />
                    <Route path="/empresa" element={<EmpresaSeccion/>} />
                    <Route path="/productos" element={<ProductosSeccion/>} />
                    <Route path="/servicios" element={<ServiciosSeccion/>} />
                </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
