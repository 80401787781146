import React from 'react';
import "./footer.css";

const Footer = () => {
    return (
        <div className="footerCont flex wrap OoS">
            <div className="footerDatosCont flex column">
                <div className='footerLineaDatosCont flex'><img className="footerIcon" src="/images/icons/placeholder.png" alt="Icon" /><p>Muñoz 2627, San Miguel, Provincia de Bs. As. </p></div>
                <div className='footerLineaDatosCont flex'><a href="mailto:gp@garciapolietilenos.com.ar" className='flex'><img className="footerIcon" src="/images/icons/email.png" alt="Email Icon" /><p>gp@garciapolietilenos.com.ar</p></a></div>
                <div className='footerLineaDatosCont flex'><a href="tel:+541146673323" className='flex'><img className="footerIcon" src="/images/icons/telephone.png" alt="Phone Icon" /><p>4667 3323</p></a></div>
                <div className='footerLineaDatosCont footerWs flex'><a href="https://wa.me/+5491137563767" target='_blank' rel="noreferrer" className='flex'><img className="footerIcon" src="/images/icons/whatsapp.png" alt="Ws Icon" /><p>11 3756 3767</p></a></div>	
                <div className='footerLineaDatosCont flex'><img className="footerIcon" src="/images/icons/clock.png" alt="Clock Icon" /><p>Lunes a Viernes de 8:00 AM A 17:00 PM</p></div>
                <div className='footerLineaDatosCont footerLineaDatosContRedes flex'>
                    <img className="footerIcon" src="/images/icons/redes.png" alt="Redes Icon" />
                    <p>Redes Sociales</p>
                    <a href="https://www.facebook.com/GarciaPolietilenos" target='_blank' rel="noreferrer" className='redesLink flex'>
                        <img className="footerIcon footerIconRedes" src="/images/icons/facebook.png" alt="Facebook Icon" />
                    </a>
                    <a href="https://www.instagram.com/garciapolietilenos.com.ar/" target='_blank' rel="noreferrer" className='redesLink flex'>
                        <img className="footerIcon footerIconRedes" src="/images/icons/instagram.png" alt="Instagram Icon" />
                    </a>
                </div>
            </div>
            <div className='footerMapaCont OoS'>
                <iframe className='footerMapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4648.307297558712!2d-58.72307034343525!3d-34.53203797274839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbd43d0300dbf%3A0xd340b59d45bc864c!2sPolietilenos%20Garc%C3%ADa!5e0!3m2!1sen!2sar!4v1684304126237!5m2!1sen!2sar" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Map"></iframe>
            </div>
        </div>
    );
}

export default Footer;
