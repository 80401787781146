import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import MenuOpciones from "./MenuOpciones";
import "./navBar2.css";

const NavBar2 = () => {

    const thisLocation = useLocation();
    
    useEffect(() => {

        /******************************************************** Igualar Alturas en secciones ***************************************************/

        const igualarAlturas = () => {
            if (window.innerWidth > window.innerHeight) {
                const filas = document.querySelectorAll(".igualarAlturas");
                    if (filas) {
                    for (const fila of filas) {
                        fila.lastChild.style.height = fila.firstChild.offsetHeight + "px";
                    }
                }
            }
        }
        window.addEventListener("resize", igualarAlturas);
        igualarAlturas(); 

        /*************************************************************** OoS *******************************************************************/

        const OoS = () => {
            const elements = document.getElementsByClassName("OoS");            //Para que al cargar la página se produzca el efecto de OoS (Opacity on Scroll) sin hacer scroll
            for (const element of elements) {                                       
                if (element.getBoundingClientRect().top < window.innerHeight) {     
                    element.classList.add("OoSS");
                };
            };
        }
        window.addEventListener("resize", OoS);
        window.addEventListener("orientationchange", OoS);
        window.addEventListener("scroll", OoS);
        OoS();

    }, [thisLocation]);   
      
    /********************************************** Menu Desktop *********************************************/

    useEffect(() => {   
        
        const menu = document.querySelector(".menuContListaOpciones").childNodes;
        for (const opcion of menu) {
            if (opcion.hasChildNodes() && opcion.lastChild.getAttribute("name") === "subMenu") {            //Recorre el menu para agregar listeners a las opciones con subopciones
                opcion.firstChild.innerHTML += "<div class='flechita'><div>"
                opcion.lastChild.style.display = "none";
                opcion.addEventListener("click", () => {        //Al hacer click de dispara primero el evento "mouseover" y despues el evento "click"
                    opcion.lastChild.style.display === "flex" ? opcion.lastChild.style.display = "none" : opcion.lastChild.style.display = "flex";
                    opcion.lastChild.setAttribute("mouseClick", "true");
                })
                opcion.addEventListener("mouseover", () => {
                    setTimeout(() => {          //El settimeout 0 es para "esperar" primero que el atributo "mouseClick" cambie a "true" al hacer click
                        if (
                            opcion.lastChild.getAttribute("mouseClick") === "false" 
                            || 
                            !opcion.lastChild.getAttribute("mouseClick")) opcion.lastChild.style.display = "flex";    //Para que la primera vez al hacer mouseover se abra el submenu
                    }, 0);
                })
                opcion.addEventListener("mouseleave", () => {
                    opcion.lastChild.style.display = "none";
                    opcion.lastChild.setAttribute("mouseClick", "false");
                })
            }
        }

        window.addEventListener("orientationchange", () => {
            window.location.reload();
        })

        let REM;
        REM = window.innerHeight >=  window.innerWidth ? 0.01 * window.innerWidth + 10 : 0.01 * window.innerHeight + 10;
        window.addEventListener("resize", () => {
            REM = window.innerHeight >=  window.innerWidth ? 0.01 * window.innerWidth + 10 : 0.01 * window.innerHeight + 10;
        })

        window.addEventListener("scroll", () => {                                   //Ocultamos los datos de contacto del menu al hacer scroll hacia abajo
            const menuContact = document.querySelector(".navBarContContacto");
            const menu = document.querySelector(".navBarCont");
            if (window.pageYOffset > 0) {
                menuContact.style.display = "none";
                menu.style.top = 1.5 * REM + "px";
            } else {
                if (menuContact.style.display === "none") {
                    menuContact.style.display = "flex";
                    menu.style.top = 3 * REM + "px";    
                }
            }    
        })
   
    }, []);


    /********************************************** Menu Portrait *********************************************/

    const [menuPortrait, setMenuPortrait] = useState();
    const [menuOff, setMenuOff] = useState(true);

    /***** Efecto de fondo oscuro *****/

    const brightness100 = () => {
        document.getElementsByTagName("html")[0].classList.toggle("brightness95");
        document.querySelector(".root").classList.toggle("brightness95");
        document.querySelector(".filtroBody").classList.toggle("brightness95");
        document.body.classList.toggle("brightness95");
        const menu = document.querySelector(".navBarCont");
        menu.classList.toggle("brightness95");
        const allMenuElements = menu.querySelectorAll("*");
        for (const me of allMenuElements) {
            me.classList.toggle("brightness95");
        }
    }

    const filtroOn = () => {
        document.querySelector(".filtroBody").classList.remove("opacityOffFiltro");
        document.querySelector(".filtroBody").classList.add("opacityOnFiltro");
        const allElements = document.querySelectorAll("*");
        for(const el of allElements) {
            el.classList.add("brightness95");
        }
        brightness100();
        document.body.style.overflow = "hidden";
    }

    const filtroOff = () => {
        document.querySelector(".filtroBody").classList.remove("opacityOnFiltro");
        document.querySelector(".filtroBody").classList.add("opacityOffFiltro");
        const allElements = document.querySelectorAll("*");
        for(const el of allElements) {
            el.classList.remove("brightness95");
        }
        document.body.style.overflow = "auto";
    }

    const cerrarMenu = (e) => {
        filtroOff();

        if (!(e.target.getAttribute("class").includes("menuContOpcionYsubOpciones") && e.target.lastChild.getAttribute("name") && e.target.lastChild.getAttribute("name").includes("subMenu"))) {

            const menuPortrait = document.querySelector(".menuPortraitAnimation")
            menuPortrait.classList.remove("menuPortraitAnimation");
            menuPortrait.classList.add("menuPortraitAnimationReverse");
            const menuAnimations = document.querySelector(".menuPortraitAnimationReverse").getAnimations();
            for (const animation of menuAnimations) {
                animation.addEventListener("finish", () => {
                    setMenuPortrait();
                })
            }

            document.body.removeEventListener("click", cerrarMenu)
            setMenuOff(true);
        }
    }

    /**************************************/

    useEffect(() => {
        if(menuPortrait) {

            const menuInDOM = document.querySelector(".menuPortraitAnimation");
            const opciones = menuInDOM.childNodes;
            for (const opcion of opciones) {
                if (opcion.hasChildNodes() && opcion.lastChild.getAttribute("name") === "subMenu") {
                    opcion.addEventListener("click", () => {
                        if (opcion.lastChild.style.maxHeight) {
                            opcion.lastChild.style.maxHeight = null 
                            opcion.lastChild.style.margin = "0"
                            opcion.style.paddingBottom = "1rem";         //Este padding tiene que coincidir con el del css (.menuContOpcionYsubOpciones)
                        } else {
                            opcion.lastChild.style.display = "block"
                            opcion.lastChild.style.maxHeight = opcion.lastChild.scrollHeight + "px";
                            opcion.lastChild.style.margin= "1rem 0"
                            opcion.style.paddingBottom = "0";
                        }
                    })
                }
            }

            const animations = document.querySelector(".menuPortraitAnimation").getAnimations();
            for (const animation of animations) {
                animation.addEventListener("finish", () => {
                    document.body.addEventListener("click", cerrarMenu)
                })
            }            

           setMenuOff(false);

        }
    // eslint-disable-next-line
    }, [menuPortrait]);
        
            
    const handleMenuPortrait = (e) => {

        if (e.target.getAttribute("class") === "menuIcon" && menuOff === false) return;
          
        if (menuOff) {

            filtroOn();
            setMenuPortrait(<MenuOpciones clase={"menuPortraitAnimation"}/>)

        } else {
            cerrarMenu(e);
        }
    }     
    
    return (
        <>
            {menuPortrait}
            <div className="filtroBody"></div>
            <div className='navBarCont navBarDimensiones OoS'>
                <div className="navBarContContacto flex wrap">
                    <a href="https://wa.me/+5491137563767" target='_blank' rel="noreferrer">
                        <div className="navBarContWs flex"><img src="/images/icons/whatsapp.png" alt="WhatsApp Icon" className="navBarIcons"/> <p>WhatsApp</p></div>
                    </a>
                    <div className="flex"> 
                        <img src="/images/icons/email2.png" alt="WhatsApp Icon" className="navBarIcons"/>
                        <a href="mailto:garciapolietilenos@gmail.com">
                            <p>garciapolietilenos@gmail.com</p>
                        </a>
                    </div>
                </div>

                <MenuOpciones/>

                <div className='navBarEsquinaDerechaColor flex'>
                    <img className="menuIcon" src="/images/menu.png" alt="Menu Icon" onClick={(e) => handleMenuPortrait(e)}/>
                </div>
                <HashLink to="/#inicio" smooth={true} className="navBarContLogo"><img className="navBarLogo" src="/images/logo_navbar.png" alt="Garcia Polietilenos Logo"/></HashLink>
            </div>
        </>
    );
}

export default NavBar2;
