import React from 'react';
import "./contacto.css";
import { useRef, useState } from 'react';
import swalPopUp from '../../utils/swal';
import Spinner1 from '../spinners/Spinner1';
import gtag_report_conversion from '../../ads/sendForm';

const Contacto = () => {

    const [spinner, setSpinner] = useState();
    const formRef = useRef();      
    
    const sendForm = async () => {
           
        const formData = new FormData(formRef.current);
        const data = Object.fromEntries(formData);

        if (data.nombre.trim() !== "" && data.telefono.trim() !== "" && data.email.trim() !== "" && data.empresa.trim() !== "" && data.mensaje.trim() !== "") {
        
            try {
                setSpinner(<Spinner1/>)
                const respJSON = await fetch ("/sendForm.php", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                const respOBJ = await respJSON.json();
                setSpinner()
                if (respOBJ.msg.includes("Error")) {
                    swalPopUp("Ops!", `${respOBJ.msg}, vuelva a intentarlo`, "error");
                } else {
                    const inputs = document.querySelectorAll(".inputsForm");
                    inputs.forEach((input) => input.value = "");
                    gtag_report_conversion();       //Envío de "Acción de conversión" "Enviar formulario" a google ads
                    swalPopUp("Enviado!", "Mensaje Enviado con Exito, Gracias por Contactarnos", "success");
                } 
                
            } catch (err) {
                setSpinner(); 
                swalPopUp("Ops!", `Error al enviar el mensaje: ${err.message}`, "error");
            }

        } else {
            swalPopUp("Ops!", "Falta Ingresar Algún Dato", "warning");       
        }
    }

    return (
        <>
            {spinner}
            <div className='contContacto flex' id="contacto">
                <div className='contFormContacto flex column'>
                    <form action="" className='form OoS' ref={formRef}>
                        <div className='contTituloContacto flex'><h2 className='titulo-contacto titulosFadeIn OoS'>ENVIANOS TU CONSULTA</h2></div>
                        <div className='contacto_tel_email flex wrap'>    
                            <div className='tel-contacto flex'><a href="https://wa.me/+5491137563767" target="_blank" rel="noreferrer" className='flex'><img src="/images/icons/whatsapp.png" alt="phone" className='imgContactoPhone'/><h2>11 3756 3767</h2></a></div>
                            <div className='email-contacto flex'><a href="mailto:gp@garciapolietilenos.com.ar" className='flex'><img src="/images/icons/email.png" alt="email" className='imgContactoEmail'/><h2>gp@garciapolietilenos.com.ar</h2></a></div>
                        </div>
                        <input type="text" name="nombre" className='formNom inputsForm' placeholder='NOMBRE'/>
                        <input type="tel" name="telefono" className='formTel inputsForm' placeholder='TELÉFONO'/>
                        <input type="email" name="email" className='formEmail inputsForm' placeholder='E-MAIL'/>
                        <input type="text" name="empresa" className='formEmpresa inputsForm' placeholder='EMPRESA'/>
                        <textarea type="text" name="mensaje" className='formMsj inputsForm' placeholder='MENSAJE' rows="20" cols="45" />
                    </form>
                    <div className='contBotonContacto flex OoS'><button type="button" className='boton-contacto' onClick={sendForm}> Enviar </button></div>
                </div>
            </div>
        </>
    );
}

export default Contacto;
